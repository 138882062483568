import React, {useState, useEffect, useRef} from "react";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";
import "./style.css";

export default function AnalyticsReport (props) {
    const permission = localStorageService.getPermissions();
    const [url, setUrl] = useState(null);
    const inputUrl = useRef(null);
    const urlRef = useRef(null);

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchUpdate();
        }
        return () => mounted = false;
    },[]);

    const fetchUpdate = () => {
        api.get(`analyticsReport/settings`)
            .then(async (res) => {
                let db_url = res?.data?.url ?? ""
                setUrl(db_url);
                inputUrl.current = db_url;
            }, err => {
                console.log(err.data)
                setUrl(null);
            });
    }

    return (
        <>  
            {
                url && (
                    <iframe title="RISC Forms Analytics" className="iframe-analytics" src={url} frameBorder="0" allowFullScreen={true}></iframe>
                )
            }
            {
                permission?.search("UPDATE_SETTINGS") !== -1 && (
                    <table className="mt-3 ml-3 mb-3">
                        <tr>
                            <th><p className="mt-0 mb-0 mr-2">URL:</p></th>
                            <th>
                                <TextBoxComponent ref={urlRef} value={url} input={e=>{inputUrl.current = e.value}} change={e=>inputUrl.current = e.value} width="1000px" height="50px"placeholder="URL" floatLabelType="Never" autocomplete={'off'}/>
                            </th>
                            <th>
                                <button
                                    className="btn btn-dark pl-4 pr-4 ml-2 mb-0 mt-0"
                                    onClick={()=>{
                                        api.post(`analyticsReport/settings`, {url: inputUrl.current})
                                            .then(async (res) => {
                                                fetchUpdate()
                                            }, err => {
                                                console.log(err.data)
                                            });
                                    }}
                                    >Save
                                </button>
                            </th>
                        </tr>
                    </table>
                )
            }
        </>
    );
} 