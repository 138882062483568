import './App.css';
import React,{useEffect, useState} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";



import Login from './components/Login/Login';

import TextAnalytics from './components/TextAnalytics/TextAnalytics';
import KeyTerms from './components/KeyTerms/KeyTerms';
import AIResult from './components/KeyTerms/AIResult/AIResult';
import FeedbackList from './components/KeyTerms/FeedbackList/FeedbackList';
import AIResultIncorrectEndorsement from './components/IncorrectEndorsement/AIResult/AIResult';
import FeedbackListIncorrectEndorsement from './components/IncorrectEndorsement/FeedbackList/FeedbackList';
import IncorrectEndorsement from './components/IncorrectEndorsement/IncorrectEndorsement';
import PerformanceOverviewReport from './components/PerformanceOverviewReport/PerformanceOverviewReport';
import AnalyticsReport from './components/AnalyticsReport/AnalyticsReport';
import KeyTermDiscovery from './components/KeyTermDiscovery/KeyTermDiscovery';
import UserManagement from './components/UserManagement/UserManagement';
import Profile from './components/UserProfile/Profile';
import JobDetails from './components/JobDetails/JobDetails';
import NoOfIER from './components/NoOfIER/NoOfIER';
import NoOfEmptyForms from './components/NoOfEmptyForms/NoOfEmptyForms';
import NoOfInvalidForms from './components/NoOfInvalidForms/NoOfInvalidForms';
import TopNKeyTerm from './components/TopNKeyTerm/TopNKeyTerm';
import { registerLicense } from '@syncfusion/ej2-base';
import localStorageService from './lib/LocalStorageService'
import api from "./lib/API";
import PrivateRoute from './PrivateRoute';

function App() {
  const [pwdExpiryReminderVisibility, setPwdExpiryReminderVisibility] = useState(localStorageService.getPwdExpiryReminder() === "true");
  localStorageService.setPwdExpiryReminderVisibilityCallback(setPwdExpiryReminderVisibility);
  return (
    <React.Fragment>
        {useLocation().pathname !== "/login" && <NavBar pwdExpiryReminderVisibility={pwdExpiryReminderVisibility} />}
        <Switch>
          <Route path = "/login" component={Login} />

          <PrivateRoute exact path="/" component={TextAnalytics} />
          {/* <PrivateRoute path="/key-terms" component={KeyTerms} /> */}
          <PrivateRoute path = "/key-terms/ai-result" component={AIResult}/>
          <PrivateRoute path="/key-terms/feedback-list" component={FeedbackList} />
          {/* <PrivateRoute path="/incorrect-endorsement" component={IncorrectEndorsement} /> */}
          <PrivateRoute path="/incorrect-endorsement/ai-result" component={AIResultIncorrectEndorsement} />
          <PrivateRoute path="/incorrect-endorsement/feedback-list" component={FeedbackListIncorrectEndorsement} />
          <PrivateRoute path="/performance-overview-report" component={PerformanceOverviewReport} />
          <PrivateRoute path="/key-term-discovery" component={KeyTermDiscovery} />
          <PrivateRoute path="/analytics-report" component={AnalyticsReport} />
          <PrivateRoute path="/user-management" component={UserManagement} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/job-details" component={JobDetails} />
          <PrivateRoute path="/no-of-ier" component={NoOfIER} />
          <PrivateRoute path="/no-of-empty-forms" component={NoOfEmptyForms} />
          <PrivateRoute path="/no-of-invalid-forms" component={NoOfInvalidForms} />
          <PrivateRoute path="/top-n-key-term" component={TopNKeyTerm} />

        </Switch>
      </React.Fragment>
  );
}

export default App;
