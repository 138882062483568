import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../lib/API";
import { useLocation, useParams, useHistory } from "react-router-dom"
import EditIcon from "../../images/Edit-icon.svg";
import DeletedPhraseIcon from "../../images/Deleted-Phrase.svg";
import CreateLogo from "../../images/Create.svg";

import KeyTermRepositoryEdit from "./Dialog/KeyTermRepositoryEdit";
import KeyTermRepositoryCreate from "./Dialog/KeyTermRepositoryCreate";
import LocalStorageService from "../../lib/LocalStorageService";
import "./style.css";

export default function KeyTermRepository (props) {
    const permission = LocalStorageService.getPermissions();
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [originalDataSource, setOriginalDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});
    const [showEdit, setShowEdit] = useState(false);
    const [showCreate,setShowCreate] = useState(false);

    const phraseRef = useRef(null);
    const [phrase, setPhrase] = useState("");
    const [discipline, setDiscipline] = useState("");
    const [keyTerm, setKeyTerm] = useState("");
    const [isAdjusted, setIsAdjusted] = useState("");
    const [disciplineList, setDisciplineList] = useState([]);
    const [keyTermList, setKeyTermList] = useState([]);
    const [isAdjustedList, setIsAdjustedList] = useState(["", "Yes", "No"]);

    const fullKeyTermList = JSON.parse(LocalStorageService.getKeyTermData());

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/keyTermRepository`)
                .then(async (res) => {
                    const phraseList = res.data.phraseList;
                    setDataSource(phraseList);
                    setOriginalDataSource(phraseList);
                    initDisciplineList();
                    initKeyTermList();
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                    setOriginalDataSource(null);
                })
        }

        return () => mounted = false;
    }, [params])

    const initDisciplineList = () => {
        const disciplineList = ["", "General", ...Object.keys(fullKeyTermList).filter(key => key !== "General").sort()];
        setDisciplineList(disciplineList);
    }

    const initKeyTermList = () => {
        setKeyTermList(["", ...Object.values(fullKeyTermList).flat().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))]);
    }

    const updateDiscipline = (discipline) => {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        if (discipline){
            disciplineKeyTermList = ["", ...disciplineKeyTermList];
        }
        else{
            disciplineKeyTermList = ["", ...Object.values(fullKeyTermList).flat()];
        }
        
        setKeyTermList(disciplineKeyTermList.sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase())));
        setDiscipline(discipline);
        setKeyTerm(disciplineKeyTermList[0]);
    }

    const updateKeyTerm = (keyTerm) => {
        setKeyTerm(keyTerm);
    }

    const fetchKeyTerms = () => {
        const newDataSource = originalDataSource.filter(item =>
            (phrase === "" || item.phrase.toLowerCase().includes(phrase.toLowerCase())) &&
            (discipline === "" || item.discipline === discipline) &&
            (keyTerm === "" || item.keyTerm === keyTerm) &&
            (isAdjusted === "" || item.isAdjusted === (isAdjusted === isAdjustedList?.[1]))
        );
        setDataSource(newDataSource);
    }

    const fetchUpdate = () => {
        api.get(`keyTermDiscovery/keyTermRepository`)
            .then(async (res) => {
                const phraseList = res.data.phraseList;
                setOriginalDataSource(phraseList);
                const newDataSource = phraseList.filter(item =>
                    (phrase === "" || item.phrase.toLowerCase().includes(phrase.toLowerCase())) &&
                    (discipline === "" || item.discipline === discipline) &&
                    (keyTerm === "" || item.keyTerm === keyTerm) &&
                    (isAdjusted === "" || item.isAdjusted === (isAdjusted === isAdjustedList?.[1]))
                );
                setDataSource(newDataSource);
            }, err => {
                console.log(err.data)
                setDataSource(null);
                setOriginalDataSource(null);
            })
    }

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={totalRecord}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const editTemplate = (row) => {
        return (
            <img src={EditIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    // setRowInfo(row);
                    // setShowEdit(true);
                }}
            />
        );
    }

    const phraseTemplate = (row) => {
        return (
            <span >
                {row.isAdjusted ? <span className="red-dot"></span> : <span className="empty-dot"></span>}
                <img style={{display: (permission?.search("UPDATE_JOB") === -1) && "none", cursor:"pointer"}} src={EditIcon} alt="" 
                    onClick={(e)=>{
                        e.stopPropagation();
                        setRowInfo(row);
                        setShowEdit(true);
                    }} />
                <span className={row.isDeleted ? 'deleted-phrase' : ''}>
                    {row.phrase}
                </span>
            </span>
        );
    }

    const keyTermTemplate = (row) => {
        return (
            <span className="keytermcolumn">
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{row.keyTerm}</span>
            </span>  
        );
    }

    const newDisciplineTemplate = (row) => {
        return row.modifiedDiscipline ? row.modifiedDiscipline : "-";
    }

    const newKeyTermTemplate = (row) => {
        return (
            row.modifiedKeyTerm
            ?
            <span className="keytermcolumn">
                {row.newKeyTerm ? "Other" : null} <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{row.modifiedKeyTerm}</span>
            </span>
            :
            "-"
        );
    }

    const lastModifiedReasonTemplate = (row) => {
        return row.modifiedReason ? row.modifiedReason : "-";
    }

    const lastModifiedByTemplate = (row) => {
        return row.modifiedBy ? row.modifiedBy : "-";
    }

    const lastModifiedTimeTemplate = (row) => {
        return row.modifiedDate ? row.modifiedDate : "-";
    }

    return (
        <>
            <div className='control-pane'>
                <div className='control-section'>
                    <div className="top">
                        <table>
                            <tr>
                                <th>Phrase</th>
                                <th>Discipline</th>
                                <th>Key Term</th>
                                <th>Manual Adjusted</th>
                                <th>&nbsp;</th>
                            </tr>
                            <tr>
                                <td style={{"padding-right": "10px"}}>
                                    <TextBoxComponent ref={phraseRef} value={phrase} input={e=>setPhrase(e.value)} change={e=>setPhrase(e.value)} width="200px" height="50px"
                                    placeholder="Phrase" floatLabelType="Never" autocomplete={'off'}/>
                                </td>
                                <td style={{"padding-right": "10px"}}>
                                    <DropDownListComponent id="discipline" placeholder="Discipline" floatLabelType="Never" width="200px" height="50px"
                                    dataSource={disciplineList}
                                    change={(e)=>{
                                        updateDiscipline(e.itemData?.value);
                                    }}
                                    />
                                </td>
                                <td style={{"padding-right": "10px"}}>
                                    <DropDownListComponent id="keyTerm" placeholder="Key Term" floatLabelType="Never" width="200px" height="50px"
                                    dataSource={keyTermList}
                                    change={(e)=>{
                                        updateKeyTerm(e.itemData?.value);
                                    }}
                                    />
                                </td>
                                <td style={{"padding-right": "10px"}}>
                                    <DropDownListComponent id="isAdjusted" placeholder="Manually Adjusted" floatLabelType="Never" width="200px" height="50px"
                                    //dataSource={[, "Manually Adjusted", "Not Manually Adjusted"]}
                                    dataSource={isAdjustedList}
                                    change={(e)=>{
                                        setIsAdjusted(e.itemData?.value);
                                    }}
                                    />
                                </td>
                                <td style={{"padding-right": "10px"}}>
                                    <button
                                    className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                    onClick={()=>{
                                        fetchKeyTerms();
                                    }}
                                    >Filter</button>
                                    <span className="separator" style={{ display: (permission?.search("UPDATE_JOB") === -1) && "none" }}></span>
                                    <button type="button" className="btn btn-dark mr-2 mb-2" style={{ display: (permission?.search("UPDATE_JOB") === -1) && "none" }} onClick={()=>setShowCreate(true)}>
                                        <img src={CreateLogo} alt="" />{` `} Create
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="float-left">
                        <span className="legend-item"><span className="red-dot"></span> Manual Adjusted (to be effective after next offline model re-training)</span>
                        <span className="legend-item"><img src={DeletedPhraseIcon} alt="" />Deleted Phrase</span>
                    </div>
                    <GridComponent
                        height="calc(100vh - 400px)"
                        allowPaging={true}
                        allowTextWrap={true}
                        allowSorting={true}
                        ref={dt}
                        //queryString={true}
                        dataSource={dataSource}
                        pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }} >
                        <ColumnsDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='phrase' headerText='Phrase' template={phraseTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='discipline' headerText='Discipline'></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='modifiedDiscipline' headerText='New Discipline' template={newDisciplineTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="200px" field='modifiedKeyTerm' headerText='New Key Term' template={newKeyTermTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='lastModifiedReason' headerText='Last Modified Reason' template={lastModifiedReasonTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='lastModifiedBy' headerText='Last Modified By' template={lastModifiedByTemplate}></ColumnDirective>
                            <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='lastModifiedTime' headerText='Last Modified Time' template={lastModifiedTimeTemplate}></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Page]} />

                    </GridComponent>
                </div>
            </div>
            {
                showEdit && <KeyTermRepositoryEdit
                    setVisibility={setShowEdit}
                    row={rowInfo}
                    fetchUpdate={fetchUpdate}
                />
            }
            {
                showCreate && <KeyTermRepositoryCreate
                    setVisibility={setShowCreate}
                    row={rowInfo}
                    fetchUpdate={fetchUpdate}
                />
            }
        </>
    );
}