const LocalStorageService = (() => {
  let _service;

  const _getService = function () {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  };

  let _pwdExpiryReminderVisibilityCallback = null;

  const _setToken = tokenObj => {
    //console.log(tokenObj);
    if (tokenObj.access) localStorage.setItem('access_token', tokenObj.access);
    if (tokenObj.refresh) localStorage.setItem('refresh_token', tokenObj.refresh);
    if (tokenObj.displayName) localStorage.setItem('display_name', tokenObj.displayName);
    if (tokenObj.permissions) localStorage.setItem('permissions', tokenObj.permissions);
    if (tokenObj.username) localStorage.setItem('username', tokenObj.username);
    if (typeof(tokenObj.pwdExpiryReminder) !== 'undefined') {
      const pwdExpiryReminder = new Boolean(tokenObj.pwdExpiryReminder).toString();
      localStorage.setItem('pwd_expiry_reminder', pwdExpiryReminder);
      if (_pwdExpiryReminderVisibilityCallback) {
        _pwdExpiryReminderVisibilityCallback(pwdExpiryReminder === "true");
      }
    }

    // if (tokenObj.userID) localStorage.setItem('userID', tokenObj.userID);
    // if (tokenObj.roleName) localStorage.setItem('roleName', tokenObj.roleName);
  };
  const _setPrevPath = (pathname) => localStorage.setItem('prevPath',pathname);
  const _setPermissions = (permissions) => localStorage.setItem('permissions',permissions);
  const _setProfileUser = (data) => localStorage.setItem('user_profile_data',data);
  const _setPwdExpiryReminder = (value) => {
    localStorage.setItem('pwd_expiry_reminder', value);
    if (_pwdExpiryReminderVisibilityCallback) {
      _pwdExpiryReminderVisibilityCallback(value === "true");
    }
  }
  const _setKeyTermData = (keyTermData) => localStorage.setItem('key_term_data', keyTermData);

  const _getAccessToken = () => localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
  const _getRefreshToken = () => localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token');
  const _getDisplayName = () => localStorage.getItem('display_name') || sessionStorage.getItem('display_name');
  const _getUsername = () => localStorage.getItem('username') || sessionStorage.getItem('username');
  const _getUserID = () => localStorage.getItem('userID') || sessionStorage.getItem('userID');
  const _getPrevPath = () => localStorage.getItem('prevPath') || sessionStorage.getItem('prevPath');
  const _getPermissions = () => localStorage.getItem('permissions') || sessionStorage.getItem('permissions');
  const _getRoleName = () => localStorage.getItem('roleName') || sessionStorage.getItem('roleName');
  const _getProfileUser = (pathname) => localStorage.getItem('user_profile_data') || sessionStorage.getItem('user_profile_data');
  const _getPwdExpiryReminder = () => localStorage.getItem('pwd_expiry_reminder') || sessionStorage.getItem('pwd_expiry_reminder');
  const _getKeyTermData = () => localStorage.getItem('key_term_data') || sessionStorage.getItem('key_term_data');

  const _clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('display_name');
    localStorage.removeItem('username');
    // localStorage.removeItem('userID');
    // localStorage.removeItem('roleName');
    localStorage.removeItem('permissions');
    localStorage.removeItem('pwd_expiry_reminder');
    localStorage.removeItem('key_term_data');
  };
  const _clearProfileUser = () => {
    localStorage.removeItem('user_profile_data');
  };
  const _clear = ()=>{
    localStorage.clear();
  };
  const _setPwdExpiryReminderVisibilityCallback = (func) => {
    _pwdExpiryReminderVisibilityCallback = func;
  };

  window.onload = function(e) {
    let accessToken = sessionStorage.getItem('access_token');
    let refreshToken = sessionStorage.getItem('refresh_token');
    let displayName = sessionStorage.getItem('display_name');
    let username = sessionStorage.getItem('username');
    let permissions = sessionStorage.getItem('permissions');
    let pwdExpiryReminder = sessionStorage.getItem('pwd_expiry_reminder');
    let keyTermData = sessionStorage.getItem('key_term_data');
    if (accessToken !== null && refreshToken !== null && displayName !== null && username !== null && permissions !== null && pwdExpiryReminder !== null && keyTermData !== null) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      localStorage.setItem('display_name', displayName);
      localStorage.setItem('username', username);
      localStorage.setItem('permissions', permissions);
      localStorage.setItem('pwd_expiry_reminder', pwdExpiryReminder);
      localStorage.setItem('key_term_data', keyTermData);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('display_name');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('permissions');
      sessionStorage.removeItem('pwd_expiry_reminder');
      sessionStorage.removeItem('key_term_data');
    }
    let totalTabs = 0;
    if (localStorage.getItem('count') !== null) {
      totalTabs = parseInt(localStorage.getItem('count'));
    }
    totalTabs++;
    localStorage.setItem('count', '' + totalTabs);
  };

  window.onbeforeunload = function(e) {
    let totalTabs = 1;
    if (localStorage.getItem('count') !== null) {
      totalTabs = parseInt(localStorage.getItem('count'));
    }
    totalTabs--;
    localStorage.setItem('count', '' + totalTabs);
    if (totalTabs < 1) {
      let accessToken = localStorage.getItem('access_token');
      let refreshToken = localStorage.getItem('refresh_token');
      let displayName = localStorage.getItem('display_name');
      let username = localStorage.getItem('username');
      let permissions = localStorage.getItem('permissions');
      let pwdExpiryReminder = localStorage.getItem('pwd_expiry_reminder');
      if (accessToken !== null && refreshToken !== null && displayName !== null && username !== null && permissions !== null && pwdExpiryReminder !== null) {
        sessionStorage.setItem('access_token', accessToken);
        sessionStorage.setItem('refresh_token', refreshToken);
        sessionStorage.setItem('display_name', displayName);
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('permissions', permissions);
        sessionStorage.setItem('pwd_expiry_reminder', pwdExpiryReminder);
      }
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('display_name');
      localStorage.removeItem('username');
      localStorage.removeItem('permissions');
      localStorage.removeItem('pwd_expiry_reminder');
    }
  };

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    getDisplayName: _getDisplayName,
    getUsername: _getUsername,
    getUserID: _getUserID,
    setPrevPath: _setPrevPath,
    getPrevPath: _getPrevPath,
    setKeyTermData: _setKeyTermData,
    getKeyTermData: _getKeyTermData,
    clearAll: _clear,
    getRoleName:_getRoleName,
    getPermissions:_getPermissions,
    setPermissions:_setPermissions,
    getProfileUser: _getProfileUser,
    setProfileUser: _setProfileUser,
    clearProfileUser: _clearProfileUser,
    setPwdExpiryReminder: _setPwdExpiryReminder,
    getPwdExpiryReminder: _getPwdExpiryReminder,
    setPwdExpiryReminderVisibilityCallback: _setPwdExpiryReminderVisibilityCallback,
  }
})();
export default LocalStorageService;
