import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import CreateIcon from "../../../images/Create.svg";
import DeleteIcon from "../../../images/icon-delete-row.svg"
import Modal from 'react-bootstrap/Modal';
import localStorageService from "../../../lib/LocalStorageService";

export default function KeyTermRepositoryEdit (props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [errorMessage, setErrorMessage] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const [isReverted, setIsReverted] = useState(false);
    const revertRef = useRef(null);
    const [commentList, setCommentList] = useState([]);
    const [discipline, setDiscipline] = useState(null);
    const [keyTermList, setKeyTermList] = useState([]);
    const [keyTerm, setKeyTerm] = useState(null);
    const [newKeyTerm, setNewKeyTerm] = useState(null);
    const [reason, setReason] = useState(null);
    const [phrase, setPhrase] = useState(null);

    const fullKeyTermList = JSON.parse(localStorageService.getKeyTermData());

    const disciplineList = Object.keys(fullKeyTermList).sort((a, b) => {
        if (a == "General") {
            return -1;
        }
        if (b == "General") {
            return 1;
        }
        return a > b ? 1 : -1;
    });

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            // console.log(props.row);
            // if (props.row.modifiedDiscipline) {
            //     setDiscipline(props.row.modifiedDiscipline);
            //     updateDiscipline(props.row.modifiedDiscipline);
            //     if (props.row.newKeyTerm) {
            //         setKeyTerm("Other");
            //         setNewKeyTerm(props.row.modifiedKeyTerm);
            //     } else {
            //         setKeyTerm(props.row.modifiedKeyTerm);
            //         setNewKeyTerm(null);
            //     }
            // } else {
            //     setDiscipline(props.row.discipline);
            //     updateDiscipline(props.row.discipline);
            //     setKeyTerm(props.row.keyTerm);
            //     setNewKeyTerm(null);
            // }
            // setReason(props.row.modifiedReason);
        }
        return () => mounted = false;
    }, [props]);

    const handleExit = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        props.fetchUpdate();
        setErrorMessage('');
    }

    const updateDiscipline = (discipline) => {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        disciplineKeyTermList = [...disciplineKeyTermList, "Other"];
        setDiscipline(discipline);
        setKeyTermList(disciplineKeyTermList);
    }

    const updateKeyTerm = (keyTerm) => {
        setKeyTerm(keyTerm);
        if (keyTerm != "Other") {
            setNewKeyTerm(null);
        }
    }

    const updateNewKeyTerm = (newKeyTerm) => {
        setNewKeyTerm(newKeyTerm);
    }

    const updateReason = (reason) => {
        setReason(reason);
    }

    const updatePhrase = () => {
        const params = {
            phrase: phrase,
            discipline: discipline,
            keyTerm: keyTerm === "Other" ? newKeyTerm : keyTerm,
            newKeyTerm: keyTerm === "Other",
            reason: reason,
        };
        api.post(`keyTermRepository`, params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err.response.data.message);
            setIsConfirmed(false);
        });
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            isModal={true}
            position={{X:'center', Y:'center'}}>
                <div class="dialog-header">
                    Create
                </div>
                <table width="90%">
                    <tbody>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Phrase</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><TextBoxComponent
                                placeholder="Phrase" width="100%" height="50px"
                                floatLabelType="Never"
                                value={phrase}
                                input={(e) => {setPhrase(e.value)}}
                                change={(e) => {setPhrase(e.value)}}
                            /></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Discipline</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><DropDownListComponent
                                placeholder="Discipline" width="50%" height="50px"
                                dataSource={disciplineList}
                                value={discipline}
                                change={(e) => {
                                    updateDiscipline(e.value);
                                }}
                            /></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Key Term</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><DropDownListComponent
                                placeholder="Key Term" width="100%" height="50px"
                                dataSource={keyTermList}
                                value={keyTerm}
                                change={(e) => {
                                    updateKeyTerm(e.value);
                                }}
                            /></td>
                        </tr>
                        {
                            keyTerm === "Other"
                            ?
                            <tr>
                                <td style={{ padding: "5px" }}><TextBoxComponent
                                    placeholder="Key Term" width="100%" height="50px"
                                    floatLabelType="Never"
                                    value={newKeyTerm}
                                    input={(e) => {updateNewKeyTerm(e.value)}}
                                    change={(e) => {updateNewKeyTerm(e.value)}}
                                /></td>
                            </tr>
                            :
                            null
                        }
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Reason</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><TextBoxComponent
                                placeholder="Reason" width="100%" height="50px"
                                floatLabelType="Never"
                                value={reason}
                                input={(e) => {updateReason(e.value)}}
                                change={(e) => {updateReason(e.value)}}
                            /></td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div style={{padding: "5px", margin: "0px 5px 0px 5px", position: "relative", top: "30px"}}>
                        {errorMessage && <p style={{ textAlign: "left !important" }} className="float-left text-left text-danger">{errorMessage}</p>}
                    </div>
                    <div style={{ paddingTop: "20px", float: "right" }}>
                        <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                            onClick={() => handleExit()}>Cancel</button>
                        <button
                            ref={confirmRef}
                            className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                            disabled={!phrase || !discipline || !keyTerm || (keyTerm == "Other" && !newKeyTerm) || !reason}
                            onClick={() => {
                                setIsConfirmed(true);
                                updatePhrase();
                            }}
                        >Confirm</button>
                    </div>
                </div> 
            </Modal>
        </div>
        
    );

}