import React, {useState,useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ExcelExport, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../lib/API";
import {useLocation, useHistory } from "react-router-dom"
import NextIcon from "../../images/Next-icon.svg";
import moment, { min } from 'moment';
import "./style.css";

export default function PerformanceOverviewReport (props) {
    const [dataSource, setDataSource] = useState(null);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecordsCount, setTotalRecordsCount] = useState(1);
    const [pageCount, setPageCount] = useState(5);

    const [currentPage, setPage] = useState(1);
    
    const location = useLocation();
    const [params, setParams] = useState(1);

    const dt = useRef(null);
    const [detailClicked, setDetailClicked] = useState(false);
    const [rowInfo, setRowInfo] = useState({});

    const gridRef = useRef(null);
    const pagerRef = useRef(null);

    const [uploadedByList, setUploadedByList] = useState([]);

    const initReportMonthFrom = new Date();
    initReportMonthFrom.setMonth(initReportMonthFrom.getMonth() - 1);
    const fromYear = initReportMonthFrom.getFullYear();
    const fromMonth = (initReportMonthFrom.getMonth() + 1).toString().padStart(2, '0');
    const initReportMonthFromStr = `${fromYear}-${fromMonth}`;

    const initReportMonthTo = new Date();
    const toYear = initReportMonthTo.getFullYear();
    const toMonth = (initReportMonthTo.getMonth() + 1).toString().padStart(2, '0');
    const initReportMonthToStr = `${toYear}-${toMonth}`;

    const [reportMonthFrom, setReportMonthFrom] = useState(initReportMonthFromStr);
    const [reportMonthTo, setReportMonthTo] = useState(initReportMonthToStr);
    const [project, setProject] = useState('');
    const [uploadedBy, setUploadedBy] = useState(0);

    const projectRef = useRef(null);

    var history = useHistory();

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            fetchPOR();
        }

        return () => mounted = false;
    },[params])
    
    function fetchPOR(){
        api.get(`por?reportMonthFrom=${reportMonthFrom}&reportMonthTo=${reportMonthTo}&project=${project}&uploadedBy=${uploadedBy}`)
        .then(async (res) => {
            const report = res.data.porList;
            res.data.uploadedBy.unshift({
                "userId": 0,
                "displayName": "",
            });
            setDataSource(report);
            setUploadedByList(res.data.uploadedBy);
        },err=>{
            console.log(err.data)
            setDataSource(null);
        })
    }

    const ierCountTemplate = (row) => {
        return (
            <span>
                <span className="form-count">{row.ierCount}</span>
                <img style={{cursor:"pointer"}} src={NextIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/no-of-ier",
                        state: {
                            porId: row.porId,
                            formType: 'ier',
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }} />
            </span>
        );
    }

    const emptyFormCountTemplate = (row) => {
        return (
            <span>
                <span className="form-count">{row.emptyFormCount}</span>
                <img style={{cursor:"pointer"}} src={NextIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/no-of-empty-forms",
                        state: {
                            porId: row.porId,
                            formType: 'emptyForms',
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }} />
            </span>
        );
    }

    const invalidFormCountTemplate = (row) => {
        return (
            <span>
                <span className="form-count">{row.invalidFormCount}</span>
                <img style={{cursor:"pointer"}} src={NextIcon} alt="" 
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/no-of-invalid-forms",
                        state: {
                            porId: row.porId,
                            formType: 'invalidForms',
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }} />
            </span>
        );
    }

    const top1KeyTermTemplate = (row) => {
        return (
            (row.top1KeyTerm && row.top1FormCount) ?
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield"
                style={{cursor:"pointer"}}
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/top-n-key-term",
                        state: {
                            porId: row.porId,
                            n: 1,
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }}>{row.top1KeyTerm} ({row.top1FormCount})</span>
            :
                <span>&nbsp;</span>
        );
    }

    const top2KeyTermTemplate = (row) => {
        return (
            (row.top2KeyTerm && row.top2FormCount) ?
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield"
                style={{cursor:"pointer"}}
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/top-n-key-term",
                        state: {
                            porId: row.porId,
                            n: 2,
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }}>{row.top2KeyTerm} ({row.top2FormCount})</span>
            :
                <span>&nbsp;</span>
        );
    }

    const top3KeyTermTemplate = (row) => {
        return (
            (row.top3KeyTerm && row.top3FormCount) ?
                <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield"
                style={{cursor:"pointer"}}
                onClick={(e)=>{
                    e.stopPropagation();
                    history.push({
                        pathname:"/top-n-key-term",
                        state: {
                            porId: row.porId,
                            n: 3,
                            reportMonth: row.reportMonth,
                            project: row.project,
                            contract: row.contract,
                            location: row.location,
                            discipline: row.discipline,
                            createdBy: row.createdBy,
                        }
                    });
                }}>{row.top3KeyTerm} ({row.top3FormCount})</span>
            :
                <span>&nbsp;</span>
        );
    }

    const downloadPOR = () => {
        if (gridRef.current) {
            const selectedRecords = gridRef.current.getSelectedRecords();
            const csvExportColumns = [
                { field: 'reportMonth', headerText: 'ReportMonth' },
                { field: 'project', headerText: 'Project' },
                { field: 'contract', headerText: 'ContractNo' },
                { field: 'location', headerText: 'Location' },
                { field: 'discipline', headerText: 'Discipline' },
                { field: 'createdBy', headerText: 'UploadedBy' },
                { field: 'ierCount', headerText: 'NumberOfIER' },
                { field: 'emptyFormCount', headerText: 'NumberOfEmptyForms' },
                { field: 'invalidFormCount', headerText: 'NumberOfInvalidForms' },
                { field: 'analyzedFormCount', headerText: 'NumberOfFormsAnalyzed' },
                { field: 'top1KeyTerm', headerText: 'Top1KeyTerm' },
                { field: 'top2KeyTerm', headerText: 'Top2KeyTerm' },
                { field: 'top3KeyTerm', headerText: 'Top3KeyTerm' },
              ];
            const csvProperties = {
                dataSource: selectedRecords,
                columns: csvExportColumns,
                fileName: 'Performance Overview Report.csv',
            };
            gridRef.current.csvExport(csvProperties);
        }
    };

    return (
        <>
        <div className="content pb-0">
        <div className="heading mb-3">
            <h4 className="page-title">Performance Overview Report</h4>
        </div>
        <div className="top">
            <table>
                <tr>
                    <th>From Report Month</th>
                    <th>&nbsp;</th>
                    <th>To Report Month</th>
                    <th>Project</th>
                    <th>Uploaded By</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                <tr>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="reportMonthFrom" value={reportMonthFrom} placeholder="From Report Month" floatLabelType="Never" width="250px" height="50px"
                        format="yyyy-MM" start="Year" depth="Year"
                        change={(e)=>{
                            console.log(e);
                            if (e.value) {
                                setReportMonthFrom(moment(e.value).format('YYYY-MM'));
                            } else {
                                setReportMonthFrom('');
                            }
                        }}
                        cleared={()=>{
                            setReportMonthFrom('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        to
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DatePickerComponent id="reportMonthTo" value={reportMonthTo} placeholder="To Report Month" floatLabelType="Never" width="250px" height="50px"
                        format="yyyy-MM" start="Year" depth="Year"
                        change={(e)=>{
                            console.log(e);
                            if (e.value) {
                                setReportMonthTo(moment(e.value).format('YYYY-MM'));
                            } else {
                                setReportMonthTo('');
                            }
                        }}
                        cleared={()=>{
                            setReportMonthTo('');
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <TextBoxComponent ref={projectRef} value={project} input={e=>setProject(e.value)} change={e=>setProject(e.value)} width="250px" height="50px"
                        placeholder="Project" floatLabelType="Never" autocomplete={'off'}/>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <DropDownListComponent id="uploadedBy" placeholder="Uploaded By" floatLabelType="Never" width="200px" height="50px"
                        fields={{text:"displayName", value:"userId"}}
                        dataSource={uploadedByList}
                        change={(e)=>{
                            setUploadedBy(e.itemData.userId);
                        }}
                        />
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            if (reportMonthFrom && reportMonthTo && reportMonthFrom > reportMonthTo) {
                                alert("'From Report Month' should be earlier than 'To Report Month'.");
                            } else {
                                fetchPOR();
                            }
                        }}
                        >Filter</button>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <span className="separator"></span>
                    </td>
                    <td style={{"padding-right": "10px"}}>
                        <button
                        className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                        onClick={()=>{
                            downloadPOR();
                        }}
                        >Download</button>
                    </td>
                </tr>
            </table>
        </div>
        </div>

        <div className="table-content p-3">
            <GridComponent
            ref={gridRef}
            dataSource={dataSource}
            height="calc(100vh - 350px)"
            allowTextWrap={true}
            allowPaging={true}
            allowSorting={true}
            // pagerTemplate={pagerTemplate}
            // created={()=>gridRef.current.setGridPager(pagerTemplate)}
            pageSettings={{pageSize: 50, pageCount: 5}}
            selectionSettings={{checkboxOnly:true}}
            allowExcelExport={true}
            //created={fetchKeyTerms}
            >
                <ColumnsDirective>
                    <ColumnDirective headerTextAlign="center" textAlign="center" type="checkbox" width="50px" />
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="110px" field='reportMonth' headerText='Report Month'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='project' headerText='Project'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='contract' headerText='Contract'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='location' headerText='Location'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='discipline' headerText='Discipline'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='createdBy' headerText='Uploaded By'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={ierCountTemplate} field='ierCount' headerText='No. of IER'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" template={emptyFormCountTemplate} field='emptyFormCount' headerText='No. of Empty Forms'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={invalidFormCountTemplate} field='invalidFormCount' headerText='No. of Invalid Forms'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='analyzedFormCount' headerText='No. of Forms Analyzed'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={top1KeyTermTemplate} field='top1KeyTerm' headerText='Top 1 Key Term'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={top2KeyTermTemplate} field='top2KeyTerm' headerText='Top 2 Key Term'></ColumnDirective>
                    <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" template={top3KeyTermTemplate} field='top3KeyTerm' headerText='Top 3 Key Term'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, ExcelExport]}/>
                
            </GridComponent>
        </div>
        </>
    );
} 