import React, {useState,useEffect, useRef} from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { ExcelExport, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import api from "../../lib/API";
import {useLocation, useHistory } from "react-router-dom"
import NextIcon from "../../images/Next-icon.svg";
import moment, { min } from 'moment';
import Pending from "./Pending";
import Approved from "./Approved";
import Rejected from "./Rejected";
import KeyTermRepository from "./KeyTermRepository";
import "./style.css";

export default function KeyTermDiscovery (props) {
    const [updatePending, setUpdatePending] = useState(false);
    const [params, setParams] = useState(1);
    const initReportMonthFrom = new Date();
    initReportMonthFrom.setMonth(initReportMonthFrom.getMonth() - 1);
    const history = useHistory()
    const resultTab = useRef(null);
    const [isShowingKeyTermFormList, setIsShowingKeyTermFormList] = useState(false);

    const showingKeyTermFormListStyle = isShowingKeyTermFormList ? {
        backgroundColor: "white",
        position: "relative",
        top: "-80px",
    } : {};

    useEffect(()=>{
        let mounted = true;
        if(mounted && resultTab.current){
            resultTab.current.animation.previous = { effect: "None" };
            resultTab.current.animation.next = { effect: "None" };
        }
        return () => mounted = false;
    },[params]);

    return (
        <>
        <div className="content pb-0">
        <div className="heading mb-3">
            <h4 className="page-title">Key Term Discovery</h4>
        </div>
            <TabComponent id='defaultTab' ref={resultTab}>
                <div className="e-tab-header">
                    <div className="p-3"> Pending </div>
                    <div className=""> Approved </div>
                    <div className=""> Rejected </div>
                    <div className=""> Key Term Repository </div>
                </div>
                <div className="e-content border pt-3 pl-3 pr-3 pb-3" style={showingKeyTermFormListStyle}>
                    <div>
                        <Pending
                            updatePending={updatePending}
                            isShowingKeyTermFormList={isShowingKeyTermFormList}
                            setIsShowingKeyTermFormList={setIsShowingKeyTermFormList}
                        />
                    </div>
                    <div>
                        <Approved />
                    </div>
                    <div>
                        <Rejected
                            setUpdatePending={setUpdatePending}
                        />
                    </div>
                    <div>
                        <KeyTermRepository />
                    </div>
                </div>
            </TabComponent>
        </div>
        </>
    );
} 