import React, { useState, useRef, useEffect } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import api from "../../../lib/API";
import LinkIcon from "../../../images/Link-icon.svg";
import CreateIcon from "../../../images/Create.svg";
import DeleteIcon from "../../../images/icon-delete-row.svg"
import Modal from 'react-bootstrap/Modal';
import localStorageService from "../../../lib/LocalStorageService";

export default function KeyTermRepositoryEdit (props) {

    const [dataSource, setDataSource] = useState(null);

    const inlineStyle = { margin: "0px 5px 0px 5px" };

    const [errorMessage, setErrorMessage] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const confirmRef = useRef(null);
    const [isReverted, setIsReverted] = useState(false);
    const revertRef = useRef(null);
    const [commentList, setCommentList] = useState([]);
    const [discipline, setDiscipline] = useState(null);
    const [keyTermList, setKeyTermList] = useState([]);
    const [keyTerm, setKeyTerm] = useState(null);
    const [newKeyTerm, setNewKeyTerm] = useState(null);
    const [reason, setReason] = useState(null);

    const fullKeyTermList = JSON.parse(localStorageService.getKeyTermData());

    const disciplineList = Object.keys(fullKeyTermList).sort((a, b) => {
        if (a == "General") {
            return -1;
        }
        if (b == "General") {
            return 1;
        }
        return a > b ? 1 : -1;
    });

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log(props.row);
            if (props.row.modifiedDiscipline) {
                setDiscipline(props.row.modifiedDiscipline);
                updateDiscipline(props.row.modifiedDiscipline);
                if (props.row.newKeyTerm) {
                    setKeyTerm("Other");
                    setNewKeyTerm(props.row.modifiedKeyTerm);
                } else {
                    setKeyTerm(props.row.modifiedKeyTerm);
                    setNewKeyTerm(null);
                }
            } else {
                setDiscipline(props.row.discipline);
                updateDiscipline(props.row.discipline);
                setKeyTerm(props.row.keyTerm);
                setNewKeyTerm(null);
            }
            setReason(props.row.modifiedReason);
        }
        return () => mounted = false;
    }, [props]);

    const handleExit = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setErrorMessage('');
    }

    const updateDiscipline = (discipline) => {
        let disciplineKeyTermList = fullKeyTermList[discipline] || [];
        disciplineKeyTermList = [...disciplineKeyTermList, "Other"];
        setDiscipline(discipline);
        setKeyTermList(disciplineKeyTermList);
    }

    const updateKeyTerm = (keyTerm) => {
        setKeyTerm(keyTerm);
        if (keyTerm != "Other") {
            setNewKeyTerm(null);
        }
    }

    const updateNewKeyTerm = (newKeyTerm) => {
        setNewKeyTerm(newKeyTerm);
    }

    const updateReason = (reason) => {
        setReason(reason);
    }

    const updatePhrase = () => {
        const params = {
            modifiedDiscipline: discipline,
            modifiedKeyTerm: keyTerm === "Other" ? newKeyTerm : keyTerm,
            newKeyTerm: keyTerm === "Other",
            modifiedReason: reason,
        };
        api.post(`keyTermRepository/${props.row.keyTermRepositoryPhaseId}`, params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    const updatePhraseReason = () => {
        const params = {
            modifiedReason: reason,
        };
        api.post(`keyTermRepository/${props.row.keyTermRepositoryPhaseId}`, params).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    const revertPhrase = () => {
        api.patch(`keyTermRepository/${props.row.keyTermRepositoryPhaseId}`).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    const deletePhrase = () => {
        api.delete(`keyTermRepository/${props.row.keyTermRepositoryPhaseId}?reason=${encodeURIComponent(reason)}`).then(async res => {
            console.log(res.data);
            props.fetchUpdate();
            handleExit();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
        });
    }

    return (
        <div id='dialog-detail'>
            <Modal 
            show={true}
            centered={true}
            minHeight="40%"
            visible={props.visibility} 
            showCloseIcon={true}
            close={()=>props.setVisibility(false)}
            isModal={true}
            position={{X:'center', Y:'center'}}>
                <div class="dialog-header">
                    Edit
                </div>
                <div>
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Phrase</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.phrase}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="dialog-body">
                    <table width="90%">
                        <tbody>
                            <tr>
                                <td><p className="font-weight-bold text-dark" width="25%" style={inlineStyle}>Last Modified Reason</p></td>
                                <td><p className="font-weight-bold text-dark" width="12%" style={inlineStyle}>Last Modified By</p></td>
                                <td><p className="font-weight-bold text-dark" style={inlineStyle}>Last Modified Time</p></td>
                            </tr>
                            <tr>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedReason ?? "-"}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedBy ?? "-"}</p></td>
                                <td><p className="mb-4 ml-1 mr-2">{props.row.modifiedDate ?? "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <table width="90%">
                    <tbody>
                        {
                            props.row.isDeleted
                            ?
                            <>
                                <tr>
                                    <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Discipline</p></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "5px" }}>{discipline}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Key Term</p></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "5px" }}>
                                    {
                                        keyTerm === "Other"
                                        ?
                                        <span>Other <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{newKeyTerm}</span></span>
                                        :
                                        <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield-long">{keyTerm}</span>
                                    }
                                    </td>
                                </tr>
                            </>
                            :
                            <>
                                <tr>
                                    <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Discipline</p></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "5px" }}><DropDownListComponent
                                        placeholder="Discipline" width="50%" height="50px"
                                        dataSource={disciplineList}
                                        value={discipline}
                                        change={(e) => {
                                            updateDiscipline(e.value);
                                        }}
                                    /></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Key Term</p></td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "5px" }}><DropDownListComponent
                                        placeholder="Key Term" width="100%" height="50px"
                                        dataSource={keyTermList}
                                        value={keyTerm}
                                        change={(e) => {
                                            updateKeyTerm(e.value);
                                        }}
                                    /></td>
                                </tr>
                                {
                                    keyTerm === "Other"
                                    ?
                                    <tr>
                                        <td style={{ padding: "5px" }}><TextBoxComponent
                                            placeholder="Key Term" width="100%" height="50px"
                                            floatLabelType="Never"
                                            value={newKeyTerm}
                                            input={(e) => {updateNewKeyTerm(e.value)}}
                                            change={(e) => {updateNewKeyTerm(e.value)}}
                                        /></td>
                                    </tr>
                                    :
                                    null
                                }
                            </>
                        }
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><p className="font-weight-bold text-dark" width="100%" style={inlineStyle}>Reason</p></td>
                        </tr>
                        <tr>
                            <td style={{ padding: "5px" }}><TextBoxComponent
                                placeholder="Reason" width="100%" height="50px"
                                floatLabelType="Never"
                                value={reason}
                                input={(e) => {updateReason(e.value)}}
                                change={(e) => {updateReason(e.value)}}
                            /></td>
                        </tr>
                    </tbody>
                </table>
                {
                    // manual created | is deleted |buttons
                    // ---------------+------------+-----------------------
                    // yes            | yes        |(not applicable)
                    // yes            | no         |Revert; Cancel, Confirm (for key term & reason)
                    // no             | yes        |Revert; Cancel, Confirm (for reason only)
                    // no             | no         |Delete, Revert; Cancel, Confirm (for key term & reason)
                    !props.row.keyTerm
                    ?
                        <div>
                            <div style={{ paddingTop: "20px", float: "left" }}>
                                <button
                                    ref={revertRef}
                                    className="btn btn-outline-danger text-danger pl-4 pr-4 mr-2 mb-2 no-hover"
                                    onClick={() => {
                                        setIsReverted(true);
                                        revertPhrase();
                                    }}
                                >Revert</button>
                            </div>
                            <div style={{ paddingTop: "20px", float: "right" }}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                    onClick={() => handleExit()}>Cancel</button>
                                <button
                                    ref={confirmRef}
                                    className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                    disabled={!reason}
                                    onClick={() => {
                                        setIsConfirmed(true);
                                        updatePhrase();
                                    }}
                                >Confirm</button>
                            </div>
                        </div>
                    :
                        props.row.isDeleted
                        ?
                        <div>
                            <div style={{ paddingTop: "20px", float: "left" }}>
                                <button
                                    ref={revertRef}
                                    className="btn btn-outline-danger text-danger pl-4 pr-4 mr-2 mb-2 no-hover"
                                    onClick={() => {
                                        setIsReverted(true);
                                        revertPhrase();
                                    }}
                                >Revert</button>
                            </div>
                            <div style={{ paddingTop: "20px", float: "right" }}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                    onClick={() => handleExit()}>Cancel</button>
                                <button
                                    ref={confirmRef}
                                    className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                    disabled={!reason}
                                    onClick={() => {
                                        setIsConfirmed(true);
                                        updatePhraseReason();
                                    }}
                                >Confirm</button>
                            </div>
                        </div> 
                        :
                        <div>
                            <div style={{ paddingTop: "20px", float: "left" }}>
                                <button
                                    className="btn btn-danger mr-2 mb-2 pl-4 pr-4"
                                    disabled={!reason}
                                    onClick={() => {
                                        console.log(reason);
                                        setIsDeleted(true);
                                        deletePhrase();
                                    }}
                                >Delete</button>
                                <button
                                    ref={revertRef}
                                    className="btn btn-outline-danger text-danger pl-4 pr-4 mr-2 mb-2 no-hover"
                                    onClick={() => {
                                        setIsReverted(true);
                                        revertPhrase();
                                    }}
                                >Revert</button>
                            </div>
                            <div style={{ paddingTop: "20px", float: "right" }}>
                                <button className="btn cancel mr-2 mb-2 pl-4 pr-4"
                                    onClick={() => handleExit()}>Cancel</button>
                                <button
                                    ref={confirmRef}
                                    className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                                    disabled={!discipline || !keyTerm || (keyTerm == "Other" && !newKeyTerm) || !reason}
                                    onClick={() => {
                                        setIsConfirmed(true);
                                        updatePhrase();
                                    }}
                                >Confirm</button>
                            </div>
                        </div> 
                }
            </Modal>
        </div>
        
    );

}