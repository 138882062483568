import React, { useRef, useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, PagerComponent } from '@syncfusion/ej2-react-grids';
import api from "../../lib/API";
import { useHistory } from "react-router-dom"

import LocalStorageService from "../../lib/LocalStorageService";

export default function Rejected (props) {
    const permission = LocalStorageService.getPermissions();
    const history = useHistory()
    const [params, setParams] = useState(1);
    const [dataSource, setDataSource] = useState(null);
    const [totalRecord, setTotalRecord] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [pageCount, setPageCount] = useState(5);

    const dt = useRef(null);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get(`keyTermDiscovery/rejected`)
                .then(async (res) => {
                    const phraseList = res.data.phraseList;
                    setDataSource(expandPhraseList(phraseList));
                }, err => {
                    console.log(err.data)
                    setDataSource(null);
                });
        }

        return () => mounted = false;
    }, [params])

    const expandPhraseList = (phraseList) => {
        let expandedPhraseList = [];
        phraseList.forEach(item => {
            let compact = (item.keyTermList.length === 1 && item.phrase === item.keyTermList[0].phrase);
            if (compact) {
                let newItem = {...item};
                newItem.main = true;
                newItem.compact = true;
                newItem.index = 0;
                expandedPhraseList.push(newItem);
            } else {
                let newItem = {...item};
                newItem.main = true;
                newItem.compact = false;
                newItem.index = null;
                expandedPhraseList.push(newItem);
                for (let i = 0; i < item.keyTermList.length; i++) {
                    newItem = {...item};
                    newItem.main = false;
                    newItem.compact = false;
                    newItem.index = i;
                    expandedPhraseList.push(newItem);
                }
            }
        });
        return expandedPhraseList;
    };

    const fetchUpdateRejected = () => {
        api.get(`keyTermDiscovery/rejected`)
            .then(async (res) => {
                const phraseList = res.data.phraseList;
                setDataSource(expandPhraseList(phraseList));
            }, err => {
                console.log(err.data)
                setDataSource(null);
            });
    }

    const pagerTemplate = () => {
        return (
            <PagerComponent
                totalRecordsCount={dataSource?.length ?? 0}
                pageSize={pageSize}
                pageCount={pageCount}
                enableQueryString={true}
                enablePersistence={true}
                click={() =>
                    setParams(window.location.href.slice(window.location.href.search('page')))}></PagerComponent>
        )
    }

    const phraseTemplate = (row) => {
        return (
            row.main
            ?
            row.phrase
            :
            row.keyTermList[row.index].phrase
        );
    }

    const disciplineTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            row.keyTermList[row.index].discipline
        );
    }

    const keyTermTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            <span className="keytermcolumn">
                {row.keyTermList[row.index].newKeyTerm ? "Other" : null} <span className="badge badge-warning p-2 font-weight-normal" id="keytermfield"
                >{row.keyTermList[row.index].keyTerm}</span>
            </span>
        );
    }

    const reasonTemplate = (row) => {
        return (
            row.index === null
            ?
            null
            :
            row.keyTermList[row.index].reason ?? "-"
        );
    }

    const confirmedByTemplate = (row) => {
        return (
            row.main
            ?
            row.confirmedBy ? row.confirmedBy : "-"
            :
            null
        );
    }

    const confirmedTimeTemplate = (row) => {
        return (
            row.main
            ?
            row.confirmedDate ? row.confirmedDate : "-"
            :
            null
        );
    }

    const restoreTemplate = (row) => {
        return (
            row.main
            ?
            <span className="float-right">
                <button type="button" className="btn cancel" style={{ display: (permission?.search("UPDATE_JOB") === -1) && "none" }}
                    onClick={async (e) =>{
                        e.stopPropagation();
                        await api.post(`keyTermDiscovery/restore/${row.keyTermDiscoveryId}`)
                        .then(res => {
                            fetchUpdateRejected();
                            props.setUpdatePending(true);
                        }).catch(error => {
                            console.log(JSON.stringify(error.response.data));
                        });
                    }}>
                    Appeal
                </button>
            </span>
            :
            null
        );
    }

    const queryCellInfoRow = (args) => {
        if (!args.data.main) {
            args.cell.style.backgroundColor = "#f7f7f7";
        }
    }

    return (
        <>
            {
                dataSource && <div className='control-pane'>
                    <div className='control-section' >
                        <GridComponent
                            height="calc(100vh - 320px)"
                            allowPaging={true}
                            allowTextWrap={true}
                            allowSorting={false}
                            //ref={dt}
                            dataSource={dataSource}
                            queryCellInfo={queryCellInfoRow}
                            pageSettings={{ enableQueryString: true, pageSize: 50, pageCount: 5 }} >
                            <ColumnsDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='phrase' headerText='Phrase' template={phraseTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='discipline' headerText='Discipline' template={disciplineTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="120px" field='keyTerm' headerText='Key Term' template={keyTermTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="100px" field='reason' headerText='Reason' template={reasonTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='confirmedBy' headerText='Rejected By' template={confirmedByTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" field='confirmedDate' headerText='Rejected Time' template={confirmedTimeTemplate}></ColumnDirective>
                                <ColumnDirective headerTextAlign="left" textAlign="left" width="80px" template={restoreTemplate}></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Page]} />

                        </GridComponent>
                    </div>
                </div>
            }
        </>
    );
}