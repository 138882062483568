import React, {useState, useEffect} from "react";
import "./login.css";
import OptixLogo from "../../images/Optix-logo-black.svg"
import TermsAndConditions from "./Dialog/TermsAndConditions";
import localStorageService from "../../lib/LocalStorageService";
import api from '../../lib/API';

import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

export default function Login(props){
    const [visibility, setVisibility] = useState(false); // for T&C

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    var thisButton;

    useEffect(()=>{
        const query = window.location.search;
        if (query) {
            props.history.replace(window.location.pathname);
            setIsLoading(true)
            localStorageService.getAccessToken();
            api.get('/auth/identity' + query, {withCredentials: true }).then(async (res) => {
                const {location, history} = props;
                localStorageService.setToken(res.data);
                setUsername('')
                setPassword('')
                setErrorMessage('')
                setIsLoading(false)
                if(!res.data.termsAccepted){
                    setVisibility(true);
                } else {
                    let pathname = "/";
                    if (res.data.permissions.indexOf("READ_JOB") === -1) {
                        pathname = "/user-management";
                    }
                    const {from} = location.state || {from: {pathname: pathname, search: ""}};
                    setTimeout(() => history.replace(from), 100);
                }
                api.get('/keyTermDiscovery/keyTermData').then((res) => {
                    localStorageService.setKeyTermData(JSON.stringify(res.data.keyTermData));
                }, err =>{
                })
            }, err => {
                setErrorMessage(`Login failed: Incorrect credential.`);
                setIsLoading(false);
            })
        }
    },[props])
    
    const onKeyDown = (e) => {
        
        if(e.keyCode === 13){
                            if (username && password) {
                    thisButton.start();
                    onLogin();
                            }
            e.preventDefault();
        }
    };
    const onChangeData = (value, field) => {
        
        if(field==="username")
            setUsername(value);
        else
            setPassword(value);

        setErrorMessage('');

    };

    const onProgress = (args) => {
        if (!isLoading) {
            args.percent = 100;
        }
    };

    const onLogin = () => {
        setIsLoading(true)
        localStorageService.getAccessToken();
        const params = {
            username: username,
            password: password,
        };
        api.post('/auth/token', params).then(async (res) => {
            const {location, history} = props;
            res.data = {...res.data, username: username}
            localStorageService.setToken(res.data);
            setUsername('')
            setPassword('')
            setErrorMessage('')
            setIsLoading(false)
            if(!res.data.termsAccepted){
                setVisibility(true);
            } else {
                let pathname = "/";
                if (res.data.permissions.indexOf("READ_JOB") === -1) {
                    pathname = "/user-management";
                }
                const {from} = location.state || {from: {pathname: pathname, search: ""}};
                setTimeout(() => history.replace(from), 100);
            }
            api.get('/keyTermDiscovery/keyTermData').then((res) => {
                localStorageService.setKeyTermData(JSON.stringify(res.data.keyTermData));
            }, err =>{
            })
        }, err => {
            setErrorMessage(`Login failed: Invalid Username or Password.`);
            setIsLoading(false);
        }) 
    };

    const onADLogin = () => {
        const baseURL = (process.env.REACT_APP_API_URL || '/api');
        const origin = window.location.href;
        window.location.replace(baseURL + "/auth/login?origin=" + origin);
    };

    return(
    <div className="back">
        <div className="container align-center" >
            
            <div className="row h-100 justify-content-center align-items-center align-center">
                <div className="col-10 col-md-8 col-lg-6">

                        <div className="login-logo mb-4">
                            <img src={OptixLogo} alt=""/>
                        </div>
                    {/* <form className="form-example pb-5 pr-5 pl-5 pt-4" action="" method="post">
                        
                    </form> */}
                    {
                        process.env.REACT_APP_PASSWORD_LOGIN ? (
                            <div className="form pb-5 pr-5 pl-5 pt-4">
                                <span className="list-title font-weight-bolder" style={{fontSize:"18px"}}>AI Text Analytics Tool</span>
                                <div className="form-group mt-4 border">
                                    <input 
                                    type="text" 
                                    className="form-control username" 
                                    id="username" 
                                    placeholder="Username" 
                                    name="username"
                                    value={username}
                                    onKeyDown={onKeyDown}
                                    onChange={(e)=>onChangeData(e.target.value,'username')} />
                                </div>
                                <div className="form-group mb-3 border">
                                    <input 
                                    type="password" 
                                    className="form-control password" 
                                    id="password" 
                                    placeholder="Password" 
                                    name="password"
                                    value={password}
                                    onKeyDown={onKeyDown}
                                    onChange={(e)=>onChangeData(e.target.value,'password')} />
                                </div>
                                {
                                    errorMessage ? (
                                        <div className='error-message text-danger'>
                                        <p>{errorMessage.replace('.','')}</p>
                                        </div>
                                    ) : <div><p style={{visibility:"hidden"}}>error message</p></div>
                                }
                                <ProgressButtonComponent
                                    cssClass="btn btn-dark p-2 pl-3 pr-3"
                                    ref={(e)=>{thisButton = e}}
                                    content="Login"
                                    disabled={!username || !password}
                                    onClick={onLogin}
                                    progress={onProgress} />
                            </div>
                        ) : (
                            <div className="form pb-5 pr-5 pl-5 pt-4">
                                <span className="list-title font-weight-bolder" style={{fontSize:"18px"}}>AI Text Analytics Tool</span>
                                {
                                    errorMessage ? (
                                        <div className='error-message text-danger'>
                                        <p>{errorMessage.replace('.','')}</p>
                                        </div>
                                    ) : <div><p style={{visibility:"hidden"}}>error message</p></div>
                                }
                                {
                                    isLoading ? (
                                        <ProgressBarComponent
                                            type="Circular"
                                            height="100px"
                                            value={20}
                                            isIndeterminate={true}
                                            progressColor="#343a40"
                                            animation={{
                                                enable: true,
                                                duration: 2000,
                                                delay: 0
                                            }}>
                                        </ProgressBarComponent>
                                    ) : (
                                        <ProgressButtonComponent
                                            cssClass="btn btn-dark p-2 pl-3 pr-3"
                                            content="Login"
                                            onClick={onADLogin}
                                            progress={onProgress} />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            
            <TermsAndConditions 
            visibility={visibility}
            setVisibility={setVisibility}
            location={props.location}
            history={props.history} />
            
        </div>
    </div>
        

    );
}